.main-error, .page-loading{
  width: 100%;	
  background-color: rgba(255,255,255,0.9);  
  z-index: 2;  
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 100px;
  border-bottom: 2px solid #0073a5;
  margin-top:105px;
  padding: 2%;
  color: red;
}

.page-loading{
  color: #868686;
}
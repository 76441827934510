@font-face {
    font-family: 'abadi_condensed';
    src: url('../fonts/abadi/Abadi_Condensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'abadi_standard';
    src: url('../fonts/abadi/Abadi_Standard.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
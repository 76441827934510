// Custom fonts
.open-light {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
}

.open-regular {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
}

.open-semi-bold {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.open-bold {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
}

.oswald-vlight {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 200;
}


.oswald-light {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300;
}

.oswald-regular {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400;
}

.oswald-medium {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 500;
}

.oswald-bold {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 600;
}

.lato-light {
  font-family: 'Lato', sans-serif !important;
  font-weight: 300;
}

.lato-regular {
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
}

.lato-medium {
  font-family: 'Lato', sans-serif !important;
  font-weight: 700;
}

.show{
  display: 'block' !important;
}

.hide{
  display: none !important;
}

.form-control{
  border-radius: 0.12rem !important;
}

.modal-content{
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 0;
  .modal-header{
    font-family: 'Open Sans', sans-serif !important;
    text-transform: uppercase;
    .modal-title{
      font-size: 15px;
    }
  }
  .modal-body{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 13px;
    color: #14143f;
  }

  .modal-footer{
    padding: 0.8rem 1rem;
    button{
      border-radius: 1.5px;
      height: 30px;
      line-height: 1;
      text-transform: uppercase;
      font-size: 12px;
    }

    button.btn-primary{
      background-color: #179ad6;
      border-color: #179ad6;
    }

  }
}

// Spinner Loader

.loader {
    position: relative;
    margin: 0 auto;
    width: 60px;
    height: 100%;
}

.loader:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.circular {
    animation: rotate 2s linear infinite;
    -webkit-animation: rotate 2s linear infinite;
    height: auto;
    transform-origin: center center;
    -webkit-transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@-webkit-keyframes color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

.tour-mask{
  opacity: 0.7;
};